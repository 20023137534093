var __jsx = React.createElement;
import React from 'react';
import ImageTextCard from '../../molecules/ImageTextCard';
import { imageRenditions } from './config';
import styled from 'styled-components';
import styles from './SecondaryPageHero.style';
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { ContentfulLivePreview } from '@contentful/live-preview';
var SecondaryPageHero = function SecondaryPageHero(_ref) {
  var _heroData$sys;
  var className = _ref.className,
    heroData = _ref.heroData,
    shouldPreloadImage = _ref.shouldPreloadImage;
  var dataSecondaryHero = useContentfulLiveUpdates(heroData);
  var title = dataSecondaryHero.title,
    backgroundImage = dataSecondaryHero.backgroundImage,
    fontColor = dataSecondaryHero.fontColor;
  var backgroundImageURL = backgroundImage ? backgroundImage.image.url : false;
  var backgroundMobileImageURL = backgroundImage ? backgroundImage.mobileImage ? backgroundImage.mobileImage.url : backgroundImage.image.url : false;

  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!backgroundImageURL) {
    backgroundImageURL = backgroundImage && backgroundImage.image.file ? backgroundImage.image.file.url : '';
  }
  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!backgroundMobileImageURL) {
    var _backgroundImage$mobi, _backgroundImage$mobi2, _backgroundImage$imag, _backgroundImage$imag2;
    backgroundMobileImageURL = backgroundImage ? backgroundImage.mobileImage ? (_backgroundImage$mobi = backgroundImage.mobileImage) === null || _backgroundImage$mobi === void 0 ? void 0 : (_backgroundImage$mobi2 = _backgroundImage$mobi.file) === null || _backgroundImage$mobi2 === void 0 ? void 0 : _backgroundImage$mobi2.url : backgroundImage === null || backgroundImage === void 0 ? void 0 : (_backgroundImage$imag = backgroundImage.image) === null || _backgroundImage$imag === void 0 ? void 0 : (_backgroundImage$imag2 = _backgroundImage$imag.file) === null || _backgroundImage$imag2 === void 0 ? void 0 : _backgroundImage$imag2.url : '';
  }
  var anchorLink = heroData && (heroData === null || heroData === void 0 ? void 0 : heroData.anchorLink) || '';
  if (shouldPreloadImage) {
    imageRenditions.priority = true;
  }
  return __jsx("div", {
    className: "".concat(className, " nva-secondary-page-hero"),
    id: anchorLink
  }, __jsx(ImageTextCard, {
    styleType: "secondary-hero",
    className: "".concat(className, " nva-secondary-page-hero__image-text-card"),
    headingText: title,
    headingType: "h1",
    imageSource: backgroundImageURL,
    mobileImageUrl: backgroundMobileImageURL,
    imageAltText: backgroundImage && backgroundImage.altText,
    imageTitle: backgroundImage && (backgroundImage.title || backgroundImage.altText),
    imageClassName: "tile-image",
    headingClassName: "tile-heading ".concat(fontColor && fontColor.toLowerCase(), " col-lg-8 col-sm-10"),
    srcSetSetting: imageRenditions,
    contentfulLivePreviewHeadingText: ContentfulLivePreview.getProps({
      entryId: heroData === null || heroData === void 0 ? void 0 : (_heroData$sys = heroData.sys) === null || _heroData$sys === void 0 ? void 0 : _heroData$sys.id,
      fieldId: "title"
    })
  }));
};
SecondaryPageHero.defaultProps = {
  className: ''
};
export default styled(SecondaryPageHero).withConfig({
  componentId: "sc-1lhjc0j-0"
})(["", ";"], styles);
export { SecondaryPageHero };